<script>
import adminProfileStructure from '@components/adminProfileStructure'
import {mapState} from "vuex";
import PaginationRow from "@components/global/PaginationRow";
import PaginatedDataMixin from "@src/mixins/PaginatedDataMixin";
import Vue from "vue";

export default {
  page: {
      title: 'Admin - Τρόφιμα',
  },
  mixins: [PaginatedDataMixin],
  components: {PaginationRow, adminProfileStructure},
  computed:{
    ...mapState({
      user: state => state.auth.currentUser
    })
  },
  data() {
    return {
      foodCategories: [],
      fields: [
        {key: 'name', label:'Όνομα', tdClass:'food-name'},
        {key: 'kcal', label: 'Ενέργεια', formatter: (value, item) => {
            return value + ' kcal'
          }},
        {key: 'carbohydrates', label: 'Υδατάνθρακες', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'proteins', label: 'Πρωτεϊνες', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'fats', label: 'Λίπη', formatter: (value, item) => {
            return value + 'g'
          }},
        {key: 'quantity', label: 'Ποσότητα', formatter: (key, value, item) => {
            let print = key;
            // check if measurementUnit ID isn't grammars, if so then change key to greek letters instead of 0.5
            if(item.measurementUnit.id !== 8){
              print = this.replaceFoodUnits(key);
            }

            let measure = this.foodMeasures.find(x=> x.id === item.measurementUnit);
            return measure.displayName ? print + ' ' + measure.displayName : print;
          }}
      ],
      foodModal: {
        foodMealCategory:{
            isBreakfast: false,
            isDekatiano: false,
            isMesimeriano: false,
            isApogeumatino: false,
            isVradino:  false
        },
      },
      error: null,
    }
  },
  created: function(){
    this.api.url = "/food";
    this.api.query.limit = 15;
    Vue.set(this.api.filters, 'list', 0);
    this.fetchFoodMeasures();
    this.getData();
  },
  methods:{
      updateFoodModalKcal(){
          if(this.foodModal.category === 5 || this.foodModal.category === 6){
              this.foodModal.subCategory = 0;
          }

          if(!this.foodModal.kcal) this.foodModal.kcal = this.foodModal.carbohydrates * 4 + this.foodModal.proteins * 4 + this.foodModal.fats *9;
      },

      fetchFoodMeasures(){
          this.$axios.get(`/food/foodCategories`).then(result=>{
              this.foodCategories = result.data || [];
          });
      },


      openEditFoodModal(record, index){
          this.error = null;
          this.foodModal = {...this.foodModal, ...record};
          this.foodModal.action = "UPDATE";
          this.$refs['food-modal'].show();
      },

      openCreateFoodModal(){
          this.error = null;
          this.foodModal = {
              id: null,
              action: 'CREATE',
              name: '',
              kcal: null,
              measurementUnit: 0,
              carbohydrates: null,
              proteins: null,
              fats: null,
              quantity: null,
              category: 0,
              isSystem: true,
              isVegan: false,
              isFasting: false,
              isVeganOnly: false,
              foodMealCategory:{
                  isBreakfast: false,
                  isDekatiano: false,
                  isMesimeriano: false,
                  isApogeumatino: false,
                  isVradino:  false
              },
              userId: null,
              list: 0
          };
          this.$refs['food-modal'].show();
      },

      updateFood(){

          this.error = null;
          if(this.foodModal.category === 5 || this.foodModal.category === 6){
              this.foodModal.subCategory = 0;
          }
          if(!this.foodModal.kcal) this.foodModal.kcal = this.foodModal.carbohydrates * 4 + this.foodModal.proteins * 4 + this.foodModal.fats *9;

          if(this.foodModal.action === 'CREATE'){
              this.$axios.post(`/admin/food/`, {food: this.foodModal}).then(result => {
                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο προστέθηκε επιτυχώς!'});
                  this.$refs['food-modal'].hide();
                  this.getData();

              }).catch(e =>{
                  this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η δημιουργία του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          }

          if(this.foodModal.action === 'UPDATE'){
              this.$axios.put(`/admin/food/`, {food: this.foodModal}).then(result => {

                  this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο ενημερώθηκε επιτυχώς!'});
                  this.$refs['food-modal'].hide();
                  this.getData();

              }).catch(e =>{
                  this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η ενημέρωση του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
              });
          }
      },

      deleteFood(){
          let message = {
              title: 'Θέλετε σίγουρα να διαγράψετε το διατροφικό πλάνο;',
              body: 'Η ενέργεια είναι μη αναστρέψιμη. Εάν πατήσετε διαγραφή, δεν θα μπορέσετε να επαναφέρετε αυτό το διατροφικό πλάνο.'
          };
          this.$dialog.confirm(message, {
              loader: true,
              okText: 'Διαγραφή',
              cancelText: 'Ακύρωση',
          }).then((dialog) => {
                  this.$axios.delete(`/admin/food/${this.foodModal.id}`).then(result => {

                      this.$notify({group: 'athlisis-notifications', type:'success',title: this.$t('success.title'), text: 'Το τρόφιμο διαγράφηκε επιτυχώς!'});
                      dialog.close();
                      this.$refs['food-modal'].hide();
                      this.getData();

                  }).catch(e =>{
                      this.$notify({group: 'athlisis-notifications', type:'error', title: this.$t('errors.title'), text: 'Η διαγραφή του τροφίμου απέτυχε. Παρακαλώ δοκιμάστε ξανά.'});
                      dialog.close();
                  });
              }).catch(()=>{});
      },
  }
}
</script>
<template>
<adminProfileStructure>
<template v-slot:admin-structure-content>
    <div class="row">
        <div class="col-12">
            <div class="card-box">
            <form class="form-inline">
                <div class="form-group"><label class="sr-only">{{ $t('search') }}</label>
                    <b-form-input v-model="api.filters.search" type="search" class="form-control" :placeholder="$t('searchPlaceholder')" debounce="400" />
                </div>

                <div class="form-group mx-sm-3">
                    <label for="list-select" class="mr-1">Λίστα:</label>
                    <select id="list-select" v-model="api.filters.list" class="custom-select" @change="getData">
                      <option value='0'>Πίνακας athlisis</option>
                      <option value='1'>Τριχοπούλου (μικροθρεπτικά)</option>
                      <option value="2">USDA Greece</option>
                      <option value="3">Συνταγές</option>
                      <option value="4">Τα τρόφιμά μου</option>
                      <option value="5">aCloud τρόφιμα</option>
                    </select>
                </div>
            </form>
            </div>
        </div>
    </div>
            <!-- end on top bar -->

      <div class="card-title">
          <span class="float-right"><b-button variant="outline-primary" class="btn-rounded" @click="openCreateFoodModal"><i class="icon-plus"></i></b-button></span>
          Υπάρχοντα τρόφιμα συστήματος
      </div>

      <div v-if="api.rows.length > 0 && foodMeasures.length > 0" class="table-responsive table-striped mb-0">
          <b-table id="food-list" :items="api.rows" :fields="fields" class="table table-hover table-borderless"
                   responsive="sm" :current-page="api.page"
                     @row-clicked="openEditFoodModal">
          </b-table>
      </div>

    <pagination-row :api="api" @fetchData="getData"></pagination-row>

  </template>

  <template v-slot:admin-content-extraspace>
      <b-modal id="food-modal" ref="food-modal" :title="foodModal.action === 'CREATE' ? 'Δημιουργία τροφίμου' : 'Επεξεργασία τροφίμου'" hide-footer body-class="p-3">

          <b-alert show variant="warning">Όταν κάνετε εισαγωγή τροφίμου, θα πρέπει να ρυθμίζετε την ποσότητα ώστε να βασίζεται σε 1 ισοδύναμο.
              Για να εισάγετε σωστά τα δεδομένα, σκεφτείτε: <br>
              1 ισοδύναμο του τροφίμου μου ισούται με <strong>x ποσότητα </strong> από <strong>y μονάδα μέτρησης</strong></b-alert>

          <b-form @submit.prevent="updateFood">

              <div class="row">
                  <div class="col-md-6">
                      <b-form-group id="edit-name" label="Όνομα τροφίμου" label-for="edit-name">
                          <b-input-group>
                              <b-form-input id="edit-name" v-model="foodModal.name" type="text"></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                      <b-form-group id="edit-kcal" label="Ενέργεια" label-for="edit-kcal">
                          <b-input-group append="kcal" >
                              <b-form-input id="edit-kcal" v-model="foodModal.kcal" type="text"></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <b-form-group id="edit-category" label="Κατηγορία τροφίμου" label-for="edit-category">
                          <label class="select-label">
                              <select v-model="foodModal.category" class="custom-select">
                                  <option v-for="item in foodCategories" :key="item.id" :value="item.id">{{ item.name }}</option>
                              </select>
                          </label>
                      </b-form-group>
                  </div>

              </div>
              <div class="row">
                  <div class="col-md-4">
                      <b-form-group id="edit-carbs" label="Υδατάνθρακες" label-for="edit-carbs">
                          <b-input-group append="g" >
                              <b-form-input id="edit-carbs" v-model="foodModal.carbohydrates" type="text" @change="updateFoodModalKcal()"></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
                  <div class="col-md-4">
                      <b-form-group id="edit-proteins" label="Πρωτεϊνες" label-for="edit-proteins">
                          <b-input-group append="g" >
                              <b-form-input id="edit-proteins" v-model="foodModal.proteins" type="text" @change="updateFoodModalKcal()"></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
                  <div class="col-md-4">
                      <b-form-group id="edit-fats" label="Λίπη" label-for="edit-fats">
                          <b-input-group append="g" >
                              <b-form-input id="edit-fats" v-model="foodModal.fats" type="text" @change="updateFoodModalKcal()" ></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
              </div>
              <div class="row">
                  <div class="col-md-6">
                      <b-form-group id="edit-quantity" label="Ποσότητα" label-for="edit-quantity">
                          <b-input-group>
                              <b-form-input id="edit-quantity" v-model="foodModal.quantity" type="text"></b-form-input>
                          </b-input-group>
                      </b-form-group>
                  </div>
                  <div class="col-md-6">
                      <b-form-group id="edit-measure" label="Μονάδα μέτρησης" label-for="edit-measure">
                          <label class="select-label">
                              <select v-model="foodModal.measurementUnit" class="custom-select">
                                  <option v-for="item in foodMeasures" :key="item.id" :value="item.id">{{ item.name }}</option>
                              </select>
                          </label>
                      </b-form-group>
                  </div>
              </div>
              <b-form-group label="Επιλογή τύπου τροφίμου:">
                  <div class="row">
                      <div class="col-md-4">
                          <b-form-checkbox id="isPrwino" v-model="foodModal.foodMealCategory.isBreakfast" name="isBreakfast">Πρωινό</b-form-checkbox>
                      </div>
                      <div class="col-md-4">
                          <b-form-checkbox id="isDekatiano" v-model="foodModal.foodMealCategory.isDekatiano" name="isDekatiano">Δεκατιανό</b-form-checkbox>
                      </div>
                      <div class="col-md-4">
                          <b-form-checkbox id="isMesimeriano" v-model="foodModal.foodMealCategory.isMesimeriano" name="isMesimeriano">Μεσημεριανό</b-form-checkbox>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-md-4">
                          <b-form-checkbox id="isApogeumatino" v-model="foodModal.foodMealCategory.isApogeumatino" name="isApogeumatino">Απογευματινό</b-form-checkbox>
                      </div>
                      <div class="col-md-4">
                          <b-form-checkbox id="isVradino" v-model="foodModal.foodMealCategory.isVradino" name="isVradino">Βραδινό</b-form-checkbox>
                      </div>
                  </div>
              </b-form-group>

              <b-form-group label="Επιλογή ιδιοτήτων:">
                  <div class="row">
                      <div class="col-md-4">
                          <b-form-checkbox id="isVegan" v-model="foodModal.isVegan" name="isVegan">Είναι vegan;</b-form-checkbox>
                      </div>
                      <div class="col-md-4">
                          <b-form-checkbox id="isFasting" v-model="foodModal.isFasting" name="isFasting" >Είναι νηστίσιμο;</b-form-checkbox>
                      </div>
                  </div>
              </b-form-group>

              <b-form-group id="button-group" class="modal-footer">
                  <div class="row">
                      <div class="col-md-4 text-left">
                          <b-button v-if="foodModal.action === 'UPDATE'" variant="danger" class="btn m-1 delete-food" @click="deleteFood">
                              <i class="fe-trash-2"></i>Διαγραφή
                          </b-button>
                      </div>
                      <div class="col-md-8 text-right" :class="foodModal.action === 'CREATE' ? 'col-md-12' : ''">
                          <!--eslint-disable-next-line-->
                          <b-button variant="light" class="btn btn-light m-1" @click="$refs['food-modal'].hide()">Κλείσιμο</b-button>
                          <b-button v-if="foodModal.action === 'CREATE'" type="submit" variant="primary" class="btn btn-primary m-1"><i class="fe-plus-circle"></i> Προσθήκη τροφίμου</b-button>
                          <b-button v-if="foodModal.action === 'UPDATE'" type="submit" variant="primary" class="btn btn-success m-1">
                              Αποθήκευση
                          </b-button>
                      </div>

                  </div>
              </b-form-group>
              <b-alert v-if="error" show variant="danger"> {{ error }}</b-alert>
          </b-form>

      </b-modal>
  </template>
</adminProfileStructure>
</template>
